
<template>
    <div>
        <div v-for="(item, index) in stock_nav.data" v-if="stock_nav.data" :key="index">
        تاریخ : {{ item.time }} <br>
        صدور : {{ item.sodor }} <br>
        ابطال : {{ item.ebtal }} <br>
        آماری : {{ item.amari }} <br>
        تعداد صدور روز : {{ item.tedad_sodor_rooz }} <br>
        تعداد ابطال روز : {{ item.tedad_ebtal_rooz }} <br>
        کل ارزش دارایی‌ها : {{ item.kol_arzesh_darayi_ha }} <br>
        تعداد واحدهای نزد سرمایه گذار : {{ item.tedad_vahedhaye_sarmayegozar }} <br>
        -------------------------------------------------
        </div>
    </div>
</template>

<script>
export default {
    name: 'StockNav',
    data() {
        return {
            stock_nav : null,
        }
    },
    mounted() {
        this.getProfileNav();
    },
    methods: {
        getProfileNav() {
            this.$helpers
                .makeRequest("GET", `/stock/profile-nav/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.stock_nav = api_response.data.response.nav.body.response.items;
                        this.widgetLoad = false;
                        // this.stock_header_summary = api_response.data.response.detail.response.summary;
                        // $('.stocks-profile').append('<link rel="stylesheet" type="text/css" href="https://static.tgju.org/components/tgju-fix/fix-custom.css">');
                        // $('body').addClass('internal profile fs-grid mprofile');
                    }
                });
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            if (!value) {
                // this.hide_demands = true;
                return '-';
            }
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        intToString(value) {
            if ((value).length > 12) {
                return 'هزار میلیارد';
            } else if ((value).length > 9) {
                return 'میلیارد';
            } else if ((value).length > 6) {
                return 'میلیون';
            } else if ((value).length > 3) {
                return '';
            } else {
                return '';
            }
        },
        roundInt(value) {
            if ((value).length > 12) {
                return parseFloat(parseFloat(value / Math.pow(1000,4)).toFixed(3));
            } else if ((value).length > 9) {
                return parseFloat(parseFloat(value / Math.pow(1000,3)).toFixed(3));
            } else if ((value).length > 6) {
                return parseFloat(parseFloat(value / Math.pow(1000,2)).toFixed(3));
            } else if ((value).length > 3) {
                return this.formatPrice(value);
            } else {
                return value;
            }
        },
        setActive(index){
            if(index == this.activeLi){
                this.activeLi = null;
            }else{
                this.activeLi = index;
            }
        },
    },
}
</script>